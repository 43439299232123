import React from 'react'
import { Alert, AlertIcon } from '@chakra-ui/react'

export default function DisplayError({ errorMessage }) {
  if (!errorMessage) {
    return null
  }

  return (
    <Alert status="error" size="xs">
      <AlertIcon />
      {errorMessage}
    </Alert>
  )
}
