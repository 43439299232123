import React from 'react';
import {
  Flex,
  Container,
  Center,
  Heading,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';
import { Stack, Select, Box } from '@chakra-ui/react';
import {
  MarketDataProvider,
  useMarketData,
} from '../contexts/marketdata.context';
import SpotPrice from '../components/spot.component';
import Risk from '../components/risk.component';
import Order from '../components/order/order.component';
import { AuthContext } from '../contexts/auth.context';

function TickerSelect() {
  const { tickers, selectedTicker, setSelectedTicker } = useMarketData();

  const handleSelect = React.useCallback(
    event => {
      setSelectedTicker(event.target.value);
    },
    [setSelectedTicker]
  );
  if (!selectedTicker) {
    return null;
  }

  return (
    <Box>
      <Select value={selectedTicker} onChange={handleSelect}>
        {tickers.map(name => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </Select>
    </Box>
  );
}

function Logger() {
  const state = useMarketData();
  console.log({ state });

  return <div>hey</div>;
}

export default function PricesPage() {
  const {
    user: { balance },
  } = React.useContext(AuthContext);

  return (
    <Container maxW="xl" centerContent paddingTop={[0, 20]}>
      <Box mb="20vh">
        <Stat>
          <StatLabel>Balance</StatLabel>
          <StatNumber>${balance}</StatNumber>
          <StatHelpText>Binance futures</StatHelpText>
        </Stat>
      </Box>
      <MarketDataProvider>
        <Stack spacing={2} align="center">
          <TickerSelect />
          <SpotPrice />
          <Risk />
          <Order />
        </Stack>
      </MarketDataProvider>
    </Container>
  );
}
