import * as R from 'ramda';
import axios from 'axios';

import { BASE_URL } from '../../constants';

export const placeDcaLong = async ({ upper, lower, stop, coin, risk }) => {
  const { data } = await axios.post(`${BASE_URL}/trade/long`, {
    upper,
    lower,
    stop,
    coin,
    risk_amount: risk,
  });

  return data;
};

export const placeMarketLong = async ({ stop, coin, risk }) => {
  const { data } = await axios.post(`${BASE_URL}/trade/market-long`, {
    stop,
    coin,
    risk_amount: risk,
  });
  return data;
};
