import React from 'react';
import * as R from 'ramda';
import useSocket from 'react-use-websocket';
import { Flex, Container, Center } from '@chakra-ui/react';
import PositionBox from '../components/positionbox.component';
import { getPriceForTicker } from '../components/utils';

const getPositionsInfo = message => {
  return JSON.parse(message.data);
};

export default function PositionsTable() {
  const { lastMessage, readyState } = useSocket(
    'wss://naz.futures.api.naz.io/ws/positions?token=wicked'
  );
  const { lastMessage: pricesMessage, readyState: pricesReady } = useSocket(
    'wss://fstream.binance.com/ws/!markPrice@arr'
  );
  if (!readyState || !lastMessage || !pricesMessage || !pricesReady)
    return 'Loading...';

  const positions = getPositionsInfo(lastMessage);

  console.log({ positions });

  return (
    <Container maxW="xl">
      <Center>
        <Flex flexDir="column">
          {R.map(
            pos => (
              <PositionBox
                price={getPriceForTicker(pos.symbol)(pricesMessage)}
                {...pos}
              />
            ),
            R.values(positions)
          )}
        </Flex>
      </Center>
    </Container>
  );
}
