import React from 'react';
import {
  Flex,
  Text,
  Link,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';
import { useMarketData } from '../contexts/marketdata.context';

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const getURLFromTicker = ticker => {
  const symbol = ticker.slice(0, -4);
  return `https://www.binance.com/en/futures/${symbol}_USDT`;
};

export default function SpotPrice() {
  const { selectedTicker: ticker, prices } = useMarketData();
  const price = prices[ticker];
  const prevPrice = usePrevious(price);
  if (!ticker) {
    return null;
  }

  let colorScheme = 'white';
  if (price > prevPrice) {
    colorScheme = 'green.400';
  } else if (price < prevPrice) {
    colorScheme = 'red.400';
  } else {
    colorScheme = 'grey.400';
  }

  // const riskDistanceText = riskDistance ? `${riskDistance.toFixed(2)}%` : '';
  // let riskColorScheme = 'white';
  // if (riskDistance > 20) {
  //   riskColorScheme = 'orange.400';
  // }
  // if (riskDistance > 30) {
  //   riskColorScheme = 'red.400';
  // }

  return (
    <Flex flexDir="column">
      <Stat>
        <Link href={getURLFromTicker(ticker)} isExternal>
          <StatLabel fontSize={18}>{ticker}</StatLabel>
        </Link>
        <StatNumber color={colorScheme}>{price}</StatNumber>
        {/* {riskDistance && ( */}
        {/*   <StatLabel fontSize={18} color={riskColorScheme}> */}
        {/*     {riskDistanceText} */}
        {/*   </StatLabel> */}
        {/* )} */}
      </Stat>
    </Flex>
  );
}
