import React from 'react';

import {
  Box,
  useDisclosure,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import Limit from './limit.component';
import DCA from './dca.component';

export default function Order() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Box>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="dca-limit" mb="0" mr="3">
            Limit
          </FormLabel>
          <Switch
            id="dca-limit"
            onChange={onToggle}
            isChecked={isOpen}
            size="lg"
          />
          <FormLabel htmlFor="dca-limit" ml="3" mb="0">
            DCA
          </FormLabel>
        </FormControl>
      </Box>

      {isOpen ? <DCA /> : <Limit />}
    </>
  );
}
