import React from 'react';
import * as R from 'ramda';
import { Container, Center, Flex, StatGroup, Stat } from '@chakra-ui/react';
import SpotPrice from '../components/spot.component';

export default function TradePage() {
  return (
    <Container maxW="xl">
      <Center>
        <Flex>
          <SpotPrice ticker="BTCUSDT" price="2342384" />
        </Flex>
      </Center>
    </Container>
  );
}
