import React from 'react';
import {
  ChakraProvider,
  extendTheme,
  Box,
  VStack,
  Grid,
  Container,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';

import { Auth } from './components/auth.component';

const queryCache = new QueryCache();

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ChakraProvider theme={theme}>
        <Container maxW="xxl">
          <Box mx="auto" textAlign="center" fontSize="xl">
            <Grid minH="100vh" alignContent="flex-start">
              <ColorModeSwitcher justifySelf="flex-end" />
              <Auth />
            </Grid>
          </Box>
        </Container>
      </ChakraProvider>
    </ReactQueryCacheProvider>
  );
}

export default App;
