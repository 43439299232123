import React from 'react';
import { useMutation } from 'react-query';
import {
  IconButton,
  Box,
  Button,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import DisplayError from '../error.component';
import { useMarketData } from '../../contexts/marketdata.context';
import { placeDcaLong } from './order.service';

export default function DCABox() {
  const {
    order: { upper, lower, stop, risk },
    selectedTicker,
    setOrder,
    price,
  } = useMarketData();

  const setStop = event => setOrder({ stop: event.target.value });
  const setUpper = event => setOrder({ upper: event.target.value });
  const setLower = event => setOrder({ lower: event.target.value });

  const [
    handlePlaceDcaLong,
    { isLoading: isLimitLoading, isSuccess: isLimitSuccess, error: limitError },
  ] = useMutation(placeDcaLong);

  const onPlaceLimitOrder = React.useCallback(
    async e => {
      e.preventDefault();

      try {
        const coin = selectedTicker.substr(0, selectedTicker.indexOf('USDT'));
        const upperAmount = upper ? upper : price;
        await handlePlaceDcaLong({
          upper: upperAmount,
          lower,
          stop,
          coin,
          risk,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    [handlePlaceDcaLong, upper, lower, stop, selectedTicker, price, risk]
  );

  const isLowerAbovePrice = Number(lower) >= Number(price);
  const isLowerAboveUpper = upper && Number(lower) > Number(upper);

  const isLowerInvalid = lower && (isLowerAboveUpper || isLowerAbovePrice);

  const isStopAbovePrice = Number(stop) >= Number(price);
  const isStopAboveUpper = upper && Number(stop) >= Number(price);
  const isStopAboveLower = Number(stop) >= Number(lower);

  const isStopInvalid =
    stop &&
    ((lower && isStopAboveLower) ||
      (upper && isStopAboveUpper) ||
      isStopAbovePrice);

  const lowerAndStopGiven = stop && lower;
  const nothingInvalid = !isLowerInvalid && !isStopInvalid;
  const isButtonEnabled =
    lowerAndStopGiven && nothingInvalid && !isLimitLoading && risk;

  const isSuccess = isLimitSuccess;

  const anyError = limitError;

  return (
    <Box>
      <Stack spacing={2}>
        <InputGroup>
          <InputLeftAddon children="Upper" />
          <Input
            disabled={isLimitSuccess}
            value={upper}
            onChange={setUpper}
            type="number"
            borderLeftRadius="0"
            placeholder="Upper Bid (optional)"
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="Lower" />
          <Input
            isInvalid={isLowerInvalid}
            disabled={isLimitSuccess}
            value={lower}
            onChange={setLower}
            type="number"
            borderLeftRadius="0"
            placeholder="Lower Bid"
          />
        </InputGroup>

        <InputGroup>
          <InputLeftAddon children="SL" />
          <Input
            value={stop}
            isInvalid={isStopInvalid}
            disabled={isLimitSuccess}
            onChange={setStop}
            type="number"
            borderRadius="0"
            placeholder="Stop Loss"
          />
        </InputGroup>

        {!isSuccess ? (
          <Stack direction="row" spacing={2}>
            <Button
              disabled={!isButtonEnabled}
              isLoading={isLimitLoading}
              colorScheme={risk ? 'teal' : 'gray'}
              size="md"
              onClick={onPlaceLimitOrder}
              isFullWidth
            >
              {risk ? 'Buy / Long' : 'No risk given!'}
            </Button>
          </Stack>
        ) : (
          <IconButton disabled colorScheme="teal" icon={<CheckIcon />} />
        )}

        <DisplayError errorMessage={anyError && anyError.response} />
      </Stack>
    </Box>
  );
}
