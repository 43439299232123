import React, { useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { AuthContext } from '../contexts/auth.context';

import ErrorMessage from '../components/error.component';

export default function Login() {
  const { signIn, user } = React.useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    setIsLoading(true);
    setError('');

    try {
      await signIn({ email, password });
      setIsLoading(false);
    } catch (err) {
      setError(err.response.data);
      console.log('caught err', { err });
      setIsLoading(false);
      setEmail('');
      setPassword('');
      setShowPassword(false);
    }
  };

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box
        p={8}
        maxWidth="500px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
      >
        <Box textAlign="center">
          <Heading>Login</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <ErrorMessage errorMessage={error} />

          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              value={email}
              placeholder="Naz"
              size="lg"
              onChange={event => setEmail(event.currentTarget.value)}
            />
          </FormControl>
          <FormControl isRequired mt={6}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                value={password}
                type={showPassword ? 'text' : 'password'}
                placeholder="*******"
                size="lg"
                onChange={event => setPassword(event.currentTarget.value)}
              />
              <InputRightElement width="3rem">
                <Button h="1.5rem" size="sm" onClick={handlePasswordVisibility}>
                  {showPassword ? (
                    <Icon name="view-off" />
                  ) : (
                    <Icon name="view" />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
            isDisabled={!password || !email}
            variantColor="teal"
            variant="outline"
            type="submit"
            width="full"
            mt={4}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <CircularProgress isIndeterminate size="24px" color="teal" />
            ) : (
              'Sign In'
            )}
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}
