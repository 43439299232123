import React from 'react';
import { useQueryParams } from 'hookrouter';
import useSocket from 'react-use-websocket';

import { getAllPrices, getAllTickers } from '../components/utils';
const MarketDataContext = React.createContext();

function MarketDataProvider({ children }) {
  const [{ upper = '', lower = '', stop = '', coin = '' }] = useQueryParams();
  // const [{ coin = '' }] = useQueryParams();
  const [state, setState] = React.useState({
    tickers: [],
    prices: {},
    price: '',
    selectedTicker: '',
    setRisk: () => {},
    order: {
      risk: '',
      upper,
      lower,
      stop,
    },
  });

  const { lastMessage, readyState } = useSocket(
    'wss://fstream.binance.com/ws/!markPrice@arr@1s'
  );

  React.useEffect(() => {
    if (!lastMessage) {
      return;
    }

    setState(prevState => {
      const tickers = getAllTickers(lastMessage);
      const getSelectedTicker = () => {
        if (prevState.selectedTicker) return prevState.selectedTicker;
        if (coin) return `${coin.toUpperCase()}USDT`;
        return tickers[0];
      };
      const prices = getAllPrices(lastMessage);
      const selectedTicker = getSelectedTicker();

      const price = prices[selectedTicker];

      return {
        ...prevState,
        tickers,
        prices,
        selectedTicker,
        price,
      };
    });
  }, [lastMessage, coin]);

  const setSelectedTicker = ticker => {
    setState(prevState => ({ ...prevState, selectedTicker: ticker }));
  };

  const setOrder = order => {
    setState(prevState => ({
      ...prevState,
      order: { ...prevState.order, ...order },
    }));
  };

  const setRisk = risk => {
    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        risk,
      },
    }));
  };

  if (!readyState || !lastMessage) return 'Loading...';

  return (
    <MarketDataContext.Provider
      value={{ ...state, setSelectedTicker, setRisk, setOrder }}
    >
      {children}
    </MarketDataContext.Provider>
  );
}

function useMarketData() {
  const context = React.useContext(MarketDataContext);
  if (context === undefined) {
    throw new Error('useMarketData must be used within a MarketDataProvider');
  }
  return context;
}

export { MarketDataProvider, useMarketData };
