import React from 'react';
import {
  Box,
  Wrap,
  WrapItem,
  Stack,
  StatGroup,
  StatArrow,
  Stat,
  StatLabel,
  StatNumber,
  Badge,
  StatHelpText,
  Heading,
  Flex,
} from '@chakra-ui/react';

const createBreakPoint = (a, b) => [a, null, null, b];

export default function PositionBox({
  symbol,
  entry,
  stop,
  size,
  pnl,
  risk,
  max_risk: maxRisk,
  is_margin_sufficient: isMarginSufficient,
  has_unfilled_orders: hasUnfilledOrders,
  has_stop: hasStop,
  price,
  tp1,
  tp2,
  tp3,
}) {
  const hasTp = tp1 || tp2 || tp3;
  const pnlChange = ((pnl / risk) * 100).toFixed(2);
  return (
    <Box width={createBreakPoint(null, '1000px')}>
      <Stack
        direction={createBreakPoint('column', 'row')}
        spacing={createBreakPoint(0, 4)}
        justify="flex-end"
        width="100%"
      >
        <Flex
          flexDir="column"
          mb={4}
          mr={5}
          alignItems={createBreakPoint('center', 'flex-end')}
          justifyContent="center"
        >
          <Heading mb={2}>{symbol}</Heading>
          <Wrap spacing={createBreakPoint(1, 2)}>
            {!isMarginSufficient && (
              <WrapItem>
                <Badge variant="outline" colorScheme="red">
                  Insuffient margin
                </Badge>
              </WrapItem>
            )}

            {!hasStop && (
              <WrapItem>
                <Badge variant="outline" colorScheme="red">
                  No stop
                </Badge>
              </WrapItem>
            )}

            {hasUnfilledOrders && (
              <WrapItem>
                <Badge variant="outline" colorScheme="green">
                  Unfilled orders
                </Badge>
              </WrapItem>
            )}
          </Wrap>
        </Flex>

        <Stack
          direction={createBreakPoint('column', 'row')}
          spacing={[0, 8]}
          justify="flex-end"
          width="100%"
        >
          <StatGroup width={createBreakPoint(null, '50%')}>
            <Stack spacing={4} direction="row">
              <Stat>
                <StatLabel>Price</StatLabel>
                <StatNumber>{Number(price).toFixed(4)}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Avg Entry</StatLabel>
                <StatNumber>{entry}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Stop</StatLabel>
                <StatNumber>{stop}</StatNumber>
              </Stat>
            </Stack>
          </StatGroup>

          <PnLSection pnl={pnl} size={size} risk={risk} maxRisk={maxRisk} />

          {hasTp && (
            <StatGroup width={createBreakPoint(null, '33%')}>
              <Stack
                spacing={4}
                direction="row"
                align="center"
                justify="flex-end"
              >
                <Stat>
                  <StatLabel>TP1</StatLabel>
                  <StatNumber>0.61</StatNumber>
                  <StatHelpText>50%</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>TP2</StatLabel>
                  <StatNumber>0.65</StatNumber>
                  <StatHelpText>25%</StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>TP3</StatLabel>
                  <StatNumber>0.88</StatNumber>
                  <StatHelpText>25%</StatHelpText>
                </Stat>
              </Stack>
            </StatGroup>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

const formatDollarAmount = amt => `$${Math.abs(amt)}`;

function PnLSection({ pnl, size, risk, maxRisk }) {
  const maxLoss = -1 * risk;
  const riskDisplay = Math.max(risk, 0);
  const maxRiskDisplay = Math.max(maxRisk, 0);

  return (
    <StatGroup width={createBreakPoint(null, '50%')}>
      <Stack spacing={4} direction="row">
        <Stat>
          <StatLabel>Size</StatLabel>
          <StatNumber>{size}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>PnL</StatLabel>
          <StatNumber color={pnl > 0 ? 'seagreen' : 'crimson'}>
            {formatDollarAmount(pnl)}
          </StatNumber>
          <StatHelpText color={maxLoss >= 0 ? 'seagreen' : 'crimson'}>
            {formatDollarAmount(maxLoss)}
          </StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Risk</StatLabel>
          <StatNumber>{riskDisplay}</StatNumber>
          <StatHelpText>{maxRiskDisplay}</StatHelpText>
        </Stat>
      </Stack>
    </StatGroup>
  );
}
