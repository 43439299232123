import * as R from 'ramda';

export const getAllTickers = message => {
  const allTickerData = JSON.parse(message.data);

  return R.pipe(
    R.sortBy(R.prop('s')),
    R.pluck('s'),
    R.filter(R.endsWith('USDT'))
  )(allTickerData);
};

export const getPriceForTicker = ticker => message => {
  const allTickerData = JSON.parse(message.data);

  const marketData = R.pipe(
    R.groupBy(R.prop('s')),
    R.map(R.head)
  )(allTickerData);

  return marketData[ticker].p;
};

export const getAllPrices = message => {
  const allTickerData = JSON.parse(message.data);

  const marketData = R.pipe(
    R.groupBy(R.prop('s')),
    R.map(R.head)
  )(allTickerData);

  return R.map(R.prop('p'), marketData);
};
