import React from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/auth.context';
import { useRoutes } from 'hookrouter';
import PricesPage from '../pages/prices.page';
import PositionsPage from '../pages/positions.page';
import LoginPage from '../pages/login.page';
import { BASE_URL } from '../constants';
import TradePage from '../pages/trade.page';

const routes = {
  '/': () => <PricesPage />,
  '/positions': () => <PositionsPage />,
  '/trade': () => <TradePage />,
};

const LOGIN_URL = `${BASE_URL}/login/access-token`;
const CHECK_TOKEN_URL = `${BASE_URL}/login/test-token`;

const extractData = response => ({
  token: response.data.access_token,
  user: response.data.user,
});

const USER_TOKEN_STORAGE_KEY = 'userToken';

export const Auth = () => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
            user: action.user,
          };
        case 'SIGN_IN':
          console.log('signing in with token', action.token);
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            user: action.user,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            user: null,
          };
        default:
          return prevState;
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      user: null,
    }
  );

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      const userToken = localStorage.getItem(USER_TOKEN_STORAGE_KEY);
      if (!userToken) {
        return;
      }
      // After restoring token, validate it and set as headers
      axios.defaults.headers.common.Authorization = 'Bearer ' + userToken;
      try {
        const user = await axios
          .post(CHECK_TOKEN_URL)
          .then(response => response.data);
        dispatch({ type: 'RESTORE_TOKEN', token: userToken, user });
      } catch (err) {
        console.log('token check failed', userToken);
        // localStorage.removeItem(USER_TOKEN_STORAGE_KEY)
      }
    };

    bootstrapAsync();
  }, []);
  const authContext = React.useMemo(
    () => ({
      signIn: async ({ email, password }) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `AsyncStorage`
        if (!email || !password) {
          return;
        }

        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);

        try {
          const { token, user } = await axios({
            url: LOGIN_URL,
            method: 'post',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          }).then(extractData);
          console.log({ token, user });

          localStorage.setItem(USER_TOKEN_STORAGE_KEY, token);
          axios.defaults.headers.common.Authorization = 'Bearer ' + token;
          dispatch({ type: 'SIGN_IN', token, user });
        } catch (err) {
          console.log('login attempt failed', { err });
          throw err;
        }
      },
      signOut: async () => {
        localStorage.removeItem(USER_TOKEN_STORAGE_KEY);
        dispatch({ type: 'SIGN_OUT' });
      },
      user: state.user,
    }),
    [state.user]
  );
  const routeResult = useRoutes(routes);
  return (
    <AuthContext.Provider value={authContext}>
      {state.userToken === null ? <LoginPage /> : routeResult}
    </AuthContext.Provider>
  );
};
