import React from 'react';
import { useMutation } from 'react-query';
import {
  IconButton,
  Box,
  Button,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import DisplayError from '../error.component';
import { useMarketData } from '../../contexts/marketdata.context';
import { placeDcaLong, placeMarketLong } from './order.service';

export default function LimitBox() {
  const {
    order: { upper, lower, stop, risk },
    selectedTicker,
    setOrder,
    price,
  } = useMarketData();

  const setStop = event => setOrder({ stop: event.target.value });
  const setLimit = event =>
    setOrder({ lower: event.target.value, upper: event.target.value });

  const [
    handlePlaceDcaLong,
    { isLoading: isLimitLoading, isSuccess: isLimitSuccess, error: limitError },
  ] = useMutation(placeDcaLong);

  const [
    handlePlaceMarketLong,
    {
      isLoading: isMarketLoading,
      isSuccess: isMarketSuccess,
      error: marketError,
    },
  ] = useMutation(placeMarketLong);

  const coin = selectedTicker.substr(0, selectedTicker.indexOf('USDT'));

  const onPlaceLimitOrder = React.useCallback(
    async e => {
      e.preventDefault();

      try {
        await handlePlaceDcaLong({
          upper: lower,
          lower,
          stop,
          coin,
          risk,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    [handlePlaceDcaLong, lower, stop, coin, risk]
  );
  const onPlaceMarketOrder = React.useCallback(
    async e => {
      e.preventDefault();

      try {
        await handlePlaceMarketLong({
          risk,
          stop,
          coin,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    [handlePlaceMarketLong, stop, coin, risk]
  );

  const isLowerAbovePrice = Number(lower) >= Number(price);

  const isLowerInvalid = lower && isLowerAbovePrice;

  const isStopAbovePrice = Number(stop) >= Number(price);
  const isStopAboveLower = Number(stop) >= Number(lower);

  const isStopInvalid =
    stop && ((lower && isStopAboveLower) || isStopAbovePrice);

  const lowerAndStopGiven = stop && lower;
  const nothingInvalid = !isLowerInvalid && !isStopInvalid;
  const isButtonEnabled =
    lowerAndStopGiven && nothingInvalid && !isLimitLoading;

  const isMarketEnabled = stop && !isStopAbovePrice && risk;

  const isSuccess = isLimitSuccess || isMarketSuccess;

  const anyError = limitError || marketError;

  return (
    <Box>
      <Stack spacing={2}>
        <InputGroup>
          <InputLeftAddon children="Entry" />
          <Input
            isInvalid={isLowerInvalid}
            disabled={isLimitSuccess}
            value={lower}
            onChange={setLimit}
            type="number"
            borderLeftRadius="0"
            placeholder="Entry price"
          />
        </InputGroup>

        <InputGroup>
          <InputLeftAddon children="SL" />
          <Input
            value={stop}
            isInvalid={isStopInvalid}
            disabled={isLimitSuccess}
            onChange={setStop}
            type="number"
            borderRadius="0"
            placeholder="Stop Loss"
          />
        </InputGroup>

        {!isSuccess ? (
          <Stack direction="row" spacing={2}>
            <Button
              disabled={!isButtonEnabled}
              isLoading={isLimitLoading}
              colorScheme={risk ? 'teal' : 'gray'}
              size="md"
              onClick={onPlaceLimitOrder}
              width="70%"
            >
              {risk ? 'Buy / Long' : 'No risk given!'}
            </Button>
            <Button
              colorScheme={risk ? 'red' : 'gray'}
              variant="solid"
              isLoading={isMarketLoading}
              disabled={!isMarketEnabled}
              onClick={onPlaceMarketOrder}
            >
              {risk ? 'Market' : 'No risk given!'}
            </Button>
          </Stack>
        ) : (
          <IconButton disabled colorScheme="teal" icon={<CheckIcon />} />
        )}

        <DisplayError errorMessage={anyError && anyError.response.data} />
      </Stack>
    </Box>
  );
}
