import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { useMarketData } from '../contexts/marketdata.context';

export default function Risk() {
  const {
    order: { risk },
    setRisk,
  } = useMarketData();

  const handleChange = event => setRisk(event.target.value);

  return (
    <Box>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="green.300"
          fontSize="1.2em"
          children="$"
        />
        <Input
          type="number"
          value={risk}
          onChange={handleChange}
          placeholder="Risk"
        />
      </InputGroup>
    </Box>
  );
}
